import React from "react"
import {
    HashRouter as Router,
    Switch,
    Route,
    Link,
    NavLink
} from "react-router-dom";
import "../../components/layout.css"
import "../../components/leftnav.css"
import 'antd/dist/antd.css';
import { Tabs, Tooltip, Popover } from 'antd';

import Rightnav from "../../components/rightnav"
import Title from "../../components/title"
import Chouti from '../../components/chouti';
import Chakanyuantu from '../../components/chakanyuantu';
import SuperIcon from '../../components/icon';


const { TabPane } = Tabs;
const list = [{ title: "满足需求", maodian: "manzuxuqiu" }, { title: "提升体验", maodian: "youhuatiyan" }];


function callback(key) {
    console.log(key);
}


const AboutYuanze = () => (
    <div style={{ width: "100%" }}>
        <Rightnav list={list} />
        <div class="main">
            <Title
                title="关于交互原则"
                content={"这里的交互原则都是广为流传，年代久远的经典理论，值得了解并记在心中，灵活运用。"}
                page="Web组件"
            />
            <p>但是这些传统的交互原则，针对的是“人”这一宽泛的概念。因此只是提高了产品最基本的易用性。<br></br>在现代，各家产品的体验设计都做的不错，这些原则对于产品的体验竞争力提升有限。实际更好的体验还需要深入细分用户。</p>


        </div>
    </div>

)

export default AboutYuanze
