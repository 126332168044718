import React from "react"
import {
    HashRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
    useRouteMatch
} from "react-router-dom";
import "../../components/layout.css"
import "../../components/leftnav.css"
import 'antd/dist/antd.css';
import Kano from "./kano";
import AboutSheji from "./aboutsheji";
import Diamond from "./diamond"



const activeStyles = {
    color: "#2196f3",
    background: "#E3F2FD"
}



function JiaohuSheji() {
    let { path, url } = useRouteMatch();
    return (
        <Router>
            <div className="leftnav">
                <ul>
                    <li><NavLink to={`${url}/aboutsheji`} activeStyle={activeStyles}>关于交互设计</NavLink></li>
                    <li>
                        <span>设计流程</span>
                        <ul class="secondnav">
                            <li><NavLink to={`${url}/diamond`} activeStyle={activeStyles} >双钻模型</NavLink></li>
                        </ul>
                    </li>
                    <li>
                        <span>需求分级</span>
                        <ul class="secondnav">
                            <li><NavLink to={`${url}/kano`} activeStyle={activeStyles} >Kano模型</NavLink></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Switch>
                <Route exact path={path}>
                    <AboutSheji />
                </Route>
                <Route path={`${path}/diamond`}><Diamond /></Route>
                <Route path={`${path}/kano`}><Kano /></Route>
            </Switch>
        </Router >
    )
}

export default JiaohuSheji
